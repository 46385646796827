/* eslint-disable import/first */
import React from "react";
import { ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import { Home, Monitor, Users, Map } from "react-feather";

import AuthGuard from "../components/AuthGuard";
import Landing from "../pages/presentation/Landing";
import Default from "../pages/dashboards/Default";

// TODO MAYBE LAZY IMPORT
import PublicMap from "../pages/publicMap";
import UserVisibilityFilter from "../components/UserVisibilityFilter";
const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const interactiveMapRoute = {
  header: "Data Access",
  id: "Interactive Map",
  icon: <Map />,
  path: ROUTES.PUBLIC_MAP,
  name: "Interactive Map",
  component: PublicMap,
  guard: AuthGuard,
  visibilityFilter: UserVisibilityFilter,
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
  guard: AuthGuard,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const mainRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  icon: <Home />,
  component: Default,
  children: null,
  containsHome: true,
  guard: AuthGuard,
  visibilityFilter: UserVisibilityFilter,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [mainRoutes, accountRoutes];

export const dashboardMaxContentLayoutRoutes = [interactiveMapRoute];

// Routes using the Auth layout
export const authLayoutRoutes = [accountRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes using the full screen map layout
export const fullscreenMapRoutes = [];

// Routes that are protected
export const protectedRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [mainRoutes, interactiveMapRoute];
