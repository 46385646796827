import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid as MuiGrid,
  Accordion,
  AccordionDetails,
  Typography as MuiTypography,
  Tooltip,
} from "@material-ui/core";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { spacing } from "@material-ui/system";
import { firstOfYear, lastOfYear, lineColors } from "../../../utils";
import DatePicker from "../../../components/pickers/DatePicker";
import Panel from "../../../components/panels/Panel";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import ExportDataButton from "../../../components/graphs/ExportDataButton";
import SaveRefButton from "../../../components/graphs/SaveRefButton";
import TimeseriesLineChart from "../../../components/graphs/TimeseriesLineChart";
import IconButton from "@material-ui/core/IconButton";
import { Close, Room } from "@material-ui/icons";
import ExpandButton from "../../../components/graphs/ExpandButton";

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0;
  }

  to {
    transform: scale(.25);
    opacity: 1;
  }
`;

const OuterContainer = styled(Box)`
  margin-left: 49px;
  bottom: 48px;
  z-index: 3;
  position: absolute;
  max-height: 100%;
  width: calc(100% - 49px - 49px);
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  animation: ${({ open }) => (open ? fadeIn : fadeOut)} 0.5s linear;
  transition: visibility 0.5s linear;
`;

const Viz = styled.div`
  height: ${({ height }) => height};
  max-width: 100%;
`;

const TimeseriesWrapper = styled.div`
  height: calc(100% - 74px - 50px);
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: calc(${({ height }) => height} - 146px);
  width: 100%;
`;

const CircleMarker = styled.div`
  text-align: center;
  border-radius: 50%;
  color: white;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 50px;
  height: 50px;
  line-height: 66px;
  margin-right: 13px;
`;

const CloseContainer = styled.div`
  // position: absolute;
  display: flex;
  justify-content: end;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: -10px;
`;

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const DataViz = ({
  open = false,
  dataVizWellNumber,
  dataVizGraphType,
  onClose,
}) => {
  const divSaveRef = useRef(null);
  const graphSaveRef = useRef(null);

  const defaultFilterValues = {
    startDate: null,
    endDate: new Date(),
  };

  const [filterValues, setFilterValues] = useState(defaultFilterValues);
  const changeFilterValues = (name, value) => {
    setFilterValues((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const [currentSelectedTimeseriesData, setCurrentSelectedTimeseriesData] =
    useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  useEffect(() => {
    if (dataVizWellNumber && dataVizGraphType) {
      setIsDataLoading(true);
      async function send() {
        try {
          const { data: results } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/${dataVizGraphType}/${dataVizWellNumber}`
          );

          if (results.length) {
            setCurrentSelectedTimeseriesData(results);
          } else {
            setCurrentSelectedTimeseriesData(null);
          }
          setIsDataLoading(false);
        } catch (err) {
          // Is this error because we cancelled it ourselves?
          if (axios.isCancel(err)) {
            console.log(`call was cancelled`);
          } else {
            console.error(err);
          }
          setIsDataLoading(false);
        }
      }
      send();
    }
  }, [dataVizWellNumber, dataVizGraphType]); // eslint-disable-line

  const [rightAxis, setRightAxis] = useState({
    label: "Daily Pumping, AF",
    field: "daily_pumping",
    units: "AF",
  });
  const handleToggleUnits = () => {
    setRightAxis((prevState) => {
      if (prevState.field === "daily_pumping") {
        return {
          label: "Pumping Rate, GPM",
          field: "pumping_rate",
          units: "GPM",
        };
      } else if (prevState.field === "pumping_rate") {
        return {
          label: "Daily Pumping, AF",
          field: "daily_pumping",
          units: "AF",
        };
      }
    });
  };

  const [annotatedLines, setAnnotatedLines] = useState({});
  const [filteredMutatedGraphData, setFilteredMutatedGraphData] = useState({});
  useEffect(() => {
    if (currentSelectedTimeseriesData?.length) {
      //mutate data for chartJS to use
      let graphData;
      if (dataVizGraphType === "ui-graph-pumping-v-waterlevels") {
        graphData = {
          labels: currentSelectedTimeseriesData.map(
            (item) => new Date(item.measured_date)
          ),
          datasets: [
            {
              label: rightAxis.label,
              type: "line",
              yAxisID: "yR",
              units: "AF",
              data: currentSelectedTimeseriesData.map(
                (item) => item[rightAxis.field]
              ),
              borderColor: lineColors.darkGray,
              backgroundColor: lineColors.darkGray,
              fill: false,
              spanGaps: false,
              borderWidth: 2,
              pointStyle: "point",
              pointRadius: 0,
              pointHoverRadius: 4,
            },
            {
              label: "Water Elevation",
              units: "Ft ab MSL",
              yAxisID: "yL",
              borderColor: lineColors.lightBlue,
              backgroundColor: lineColors.lightBlue + "4D",
              data: currentSelectedTimeseriesData.map(
                (item) => item.water_elevation
              ),
              fill: true,
              spanGaps: false,
              borderWidth: 3,
              pointStyle: "point",
              pointRadius: 0,
              pointHoverRadius: 4,
            },
          ],
        };
        const annotations = {
          annotations: {
            ...(currentSelectedTimeseriesData[0]?.ground_elevation !== null && {
              groundElevationLine: {
                type: "line",
                yScaleID: "yL",
                yMin: currentSelectedTimeseriesData[0].ground_elevation,
                yMax: currentSelectedTimeseriesData[0].ground_elevation,
                borderColor: "#94CE94",
                borderWidth: 3,
                display: false,
                label: {
                  position: "45%",
                  enabled: true,
                  backgroundColor: "#94CE94",
                  borderColor: "#94CE94",
                  borderRadius: 10,
                  borderWidth: 2,
                  color: "black",
                  content: () =>
                    "Ground Elevation: " +
                    currentSelectedTimeseriesData[0].ground_elevation +
                    " ft",
                  rotation: "auto",
                },
              },
            }),
            ...(currentSelectedTimeseriesData[0]?.well_bottom_elevation !==
              null && {
              wellBottomelevationLine: {
                type: "line",
                yScaleID: "yL",
                yMin: currentSelectedTimeseriesData[0].well_bottom_elevation,
                yMax: currentSelectedTimeseriesData[0].well_bottom_elevation,
                borderColor: "#E8BA6C",
                borderWidth: 3,
                display: false,
                label: {
                  position: "15%",
                  enabled: true,
                  backgroundColor: "#E8BA6C",
                  borderColor: "#E8BA6C",
                  borderRadius: 10,
                  borderWidth: 2,
                  color: "white",
                  content: () =>
                    "Bottom of Well: " +
                    currentSelectedTimeseriesData[0].well_bottom_elevation +
                    " ft",
                  rotation: "auto",
                },
              },
            }),
            ...(currentSelectedTimeseriesData[0]?.pump_elevation !== null && {
              pumpElevationLine: {
                type: "line",
                yScaleID: "yL",
                yMin: currentSelectedTimeseriesData[0].pump_elevation,
                yMax: currentSelectedTimeseriesData[0].pump_elevation,
                borderColor: "#403ECD",
                borderWidth: 3,
                display: false,
                label: {
                  position: "75%",
                  enabled: true,
                  backgroundColor: "#403ECD",
                  borderColor: "#403ECD",
                  borderRadius: 10,
                  borderWidth: 2,
                  content: () =>
                    "Pump: " +
                    currentSelectedTimeseriesData[0].pump_elevation +
                    " ft",
                  rotation: "auto",
                },
              },
            }),
            ...(currentSelectedTimeseriesData[0]?.screen_top_elev !== null && {
              screenTopElevLine: {
                type: "line",
                yScaleID: "yL",
                yMin: currentSelectedTimeseriesData[0].screen_top_elev,
                yMax: currentSelectedTimeseriesData[0].screen_top_elev,
                borderColor: "black",
                borderWidth: 3,
                borderDash: [6, 6],
                borderDashOffset: 6,
                display: false,
                label: {
                  position: "start",
                  yAdjust: -15,
                  enabled: true,
                  backgroundColor: "black",
                  borderColor: "black",
                  borderRadius: 10,
                  borderWidth: 2,
                  content: () =>
                    "Top of Screen: " +
                    currentSelectedTimeseriesData[0].screen_top_elev +
                    " ft",
                  rotation: "auto",
                },
              },
            }),
            ...(currentSelectedTimeseriesData[0]?.screen_bot_elev !== null && {
              screenBotElevLine: {
                type: "line",
                yScaleID: "yL",
                yMin: currentSelectedTimeseriesData[0].screen_bot_elev,
                yMax: currentSelectedTimeseriesData[0].screen_bot_elev,
                borderColor: "black",
                borderWidth: 3,
                borderDash: [6, 6],
                borderDashOffset: 6,
                display: false,
                label: {
                  position: "end",
                  yAdjust: 15,
                  enabled: true,
                  backgroundColor: "black",
                  borderColor: "black",
                  borderRadius: 10,
                  borderWidth: 2,
                  content: () =>
                    "Bottom of Screen: " +
                    currentSelectedTimeseriesData[0].screen_bot_elev +
                    " ft",
                  rotation: "auto",
                },
              },
            }),
            ...(currentSelectedTimeseriesData[0]?.aquifer_top_elev !== null && {
              aquiferTopElevLine: {
                type: "line",
                yScaleID: "yL",
                yMin: currentSelectedTimeseriesData[0].aquifer_top_elev,
                yMax: currentSelectedTimeseriesData[0].aquifer_top_elev,
                borderColor: "#C454C4",
                borderWidth: 7,
                borderDash: [6, 6],
                borderDashOffset: 0,
                display: true,
                label: {
                  position: "30%",
                  yAdjust: -15,
                  enabled: true,
                  backgroundColor: "#C454C4",
                  borderColor: "#C454C4",
                  borderRadius: 10,
                  borderWidth: 2,
                  content: () =>
                    "Top of Aquifer: " +
                    currentSelectedTimeseriesData[0].aquifer_top_elev +
                    " ft",
                  rotation: "auto",
                },
              },
            }),
            ...(currentSelectedTimeseriesData[0]?.aquifer_bot_elev !== null && {
              aquiferBotElevLine: {
                type: "line",
                yScaleID: "yL",
                yMin: currentSelectedTimeseriesData[0].aquifer_bot_elev,
                yMax: currentSelectedTimeseriesData[0].aquifer_bot_elev,
                borderColor: "#C454C4",
                borderWidth: 7,
                borderDash: [6, 6],
                borderDashOffset: 0,
                display: true,
                label: {
                  position: "60%",
                  yAdjust: 15,
                  enabled: true,
                  backgroundColor: "#C454C4",
                  borderColor: "#C454C4",
                  borderRadius: 10,
                  borderWidth: 2,
                  content: () =>
                    "Bottom of Aquifer: " +
                    currentSelectedTimeseriesData[0].aquifer_bot_elev +
                    " ft",
                  rotation: "auto",
                },
              },
            }),
          },
        };
        setAnnotatedLines(annotations);
      }

      setFilteredMutatedGraphData(graphData);
    } else {
      setAnnotatedLines({});
      setFilteredMutatedGraphData(null);
    }
  }, [currentSelectedTimeseriesData, dataVizGraphType, rightAxis]);

  const handleToggleAnnotation = (lineLabel) => {
    setAnnotatedLines((prevState) => {
      let newState = { ...prevState };

      if (lineLabel === "screening") {
        if (newState?.annotations?.screenBotElevLine?.display !== undefined) {
          newState.annotations.screenBotElevLine.display =
            !newState.annotations.screenBotElevLine.display;
        }
        if (newState?.annotations?.screenTopElevLine?.display !== undefined) {
          newState.annotations.screenTopElevLine.display =
            !newState.annotations.screenTopElevLine.display;
        }
      } else if (lineLabel === "aquifer") {
        if (newState?.annotations?.aquiferBotElevLine?.display !== undefined) {
          newState.annotations.aquiferBotElevLine.display =
            !newState.annotations.aquiferBotElevLine.display;
        }
        if (newState?.annotations?.aquiferTopElevLine?.display !== undefined) {
          newState.annotations.aquiferTopElevLine.display =
            !newState.annotations.aquiferTopElevLine.display;
        }
      } else if (lineLabel === "well") {
        if (newState?.annotations?.pumpElevationLine?.display !== undefined) {
          newState.annotations.pumpElevationLine.display =
            !newState.annotations.pumpElevationLine.display;
        }
        if (
          newState?.annotations?.wellBottomelevationLine?.display !== undefined
        ) {
          newState.annotations.wellBottomelevationLine.display =
            !newState.annotations.wellBottomelevationLine.display;
        }
        if (newState?.annotations?.groundElevationLine?.display !== undefined) {
          newState.annotations.groundElevationLine.display =
            !newState.annotations.groundElevationLine.display;
        }
      }

      return newState;
    });
  };

  const formatTableTitle = (graphType) => {
    if (["ui-graph-pumping-v-waterlevels"].includes(graphType)) {
      return (
        <>
          <Typography variant="h4" style={{ lineHeight: 1.3 }}>
            <strong>Pumping and Water Levels for Well: </strong>
            {currentSelectedTimeseriesData[0]?.well_name ?? "NA"}
          </Typography>
          <Typography variant="subtitle1" style={{ lineHeight: 1.3 }}>
            <Box>ID: {currentSelectedTimeseriesData[0]?.well_id ?? "NA"}</Box>

            <Box>
              Aquifer: {currentSelectedTimeseriesData[0]?.aquifer ?? "NA"}
            </Box>
          </Typography>
        </>
      );
    } else {
      return null;
    }
  };

  const [dataVizHeight, setDataVizHeight] = useState({
    viz: "460px",
    timeSeries: "500px",
  });

  const handleExpand = () => {
    let newState = { ...dataVizHeight };
    if (newState.viz === "460px" && newState.timeSeries === "500px") {
      newState.viz = "70vh";
      newState.timeSeries = "70vh";
    } else {
      newState.viz = "460px";
      newState.timeSeries = "500px";
    }
    setDataVizHeight(newState);
  };

  const active = (bool) => {
    if (bool === null) return;
    return bool
      ? {
          backgroundColor: "#105973",
          transition: "transform .5s ease-in-out, box-shadow .5s ease-in-out",
          transform: "scale(.95)",
          boxShadow: "2px 1px 2px rgba(0,0,0,0.15)",
        }
      : {
          transition: "transform .5s ease-in-out, box-shadow .5s ease-in-out",
          transform: "scale(1)",
          boxShadow: "2px 5px 15px rgba(0,0,0,0.3)",
        };
  };

  return (
    <OuterContainer
      bgcolor="#ffffff"
      boxShadow="0 0 0 2px rgba(0,0,0,.1)"
      borderRadius={4}
      open={open}
    >
      <Viz height={dataVizHeight.viz}>
        <CloseContainer>
          <ExpandButton
            handleExpand={handleExpand}
            expanded={dataVizHeight.viz !== "460px"}
          />
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={onClose}
              style={{ marginLeft: "4px" }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </CloseContainer>
        {filteredMutatedGraphData && currentSelectedTimeseriesData && (
          <Panel overflowY="scroll" overflowX="hidden">
            <TimeseriesContainer
              height={dataVizHeight.timeSeries}
              ref={divSaveRef}
            >
              <Box ml={4} pt={2} pb={1} display="flex" alignItems="center">
                <CircleMarker>
                  <Room />
                </CircleMarker>
                <Box flexDirection="column" display="flex">
                  {formatTableTitle(dataVizGraphType)}
                </Box>
              </Box>

              <Box pb={1} display="flex" alignItems="center">
                <Button
                  size="small"
                  style={{
                    width: "200px",
                    marginRight: "20px",
                    ...active(
                      annotatedLines?.annotations["aquiferBotElevLine"]
                        ?.display ?? null
                    ),
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => handleToggleAnnotation("aquifer")}
                  disabled={
                    !currentSelectedTimeseriesData[0]?.aquifer_top_elev &&
                    !currentSelectedTimeseriesData[0]?.aquifer_bot_elev
                  }
                >
                  Aquifer Intervals
                </Button>

                <Button
                  size="small"
                  style={{
                    width: "200px",
                    marginRight: "20px",
                    ...active(
                      annotatedLines?.annotations["screenBotElevLine"]
                        ?.display ?? null
                    ),
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => handleToggleAnnotation("screening")}
                  disabled={
                    !currentSelectedTimeseriesData[0]?.screen_top_elev &&
                    !currentSelectedTimeseriesData[0]?.screen_bot_elev
                  }
                >
                  Screening Intervals
                </Button>

                <Button
                  size="small"
                  style={{
                    width: "200px",
                    marginRight: "20px",
                    ...active(
                      annotatedLines?.annotations["groundElevationLine"]
                        ?.display ?? null
                    ),
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => handleToggleAnnotation("well")}
                  disabled={
                    !currentSelectedTimeseriesData[0]?.ground_elevation &&
                    !currentSelectedTimeseriesData[0]?.well_bottom_elevation &&
                    !currentSelectedTimeseriesData[0]?.ground_elevation
                  }
                >
                  Well Info Intervals
                </Button>

                <Box
                  style={{ marginLeft: "auto" }}
                  data-html2canvas-ignore={"true"}
                  display="flex"
                  alignItems="center"
                >
                  <Button
                    size="small"
                    style={{
                      width: "200px",
                      marginRight: "20px",
                    }}
                    color="primary"
                    variant="contained"
                    onClick={handleToggleUnits}
                    disableRipple={false}
                  >
                    Toggle Right Axis
                  </Button>

                  <ExportDataButton
                    title="well_ndx"
                    data={currentSelectedTimeseriesData}
                    filterValues={filterValues}
                  />
                  <SaveRefButton
                    data-html2canvas-ignore
                    ref={divSaveRef}
                    title={dataVizWellNumber}
                  />
                </Box>
              </Box>

              <TimeseriesWrapper>
                <TimeseriesLineChart
                  annotatedLines={annotatedLines}
                  data={filteredMutatedGraphData}
                  // error={error}
                  isLoading={isDataLoading}
                  filterValues={filterValues}
                  yLLabel="Water Elevation, Ft ab MSL"
                  yRLLabel={rightAxis.label}
                  xLabelFormat="MMM DD YYYY"
                  ref={graphSaveRef}
                />
              </TimeseriesWrapper>
            </TimeseriesContainer>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="map"
                id="map"
              >
                <Typography variant="h4">Filters</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block" }}>
                <Grid container spacing={6} alignItems="center">
                  <Grid item xs={12} sm={6} md={5}>
                    <DatePicker
                      label="Start Date"
                      name="startDate"
                      selectedDate={filterValues.startDate}
                      setSelectedDate={changeFilterValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <DatePicker
                      label="End Date"
                      name="endDate"
                      selectedDate={filterValues.endDate}
                      setSelectedDate={changeFilterValues}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={12}>
                        <Tooltip title="Period of Record">
                          <Button
                            size="small"
                            style={{ width: "100%" }}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              changeFilterValues("startDate", null);
                              changeFilterValues("endDate", new Date());
                            }}
                          >
                            Record
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6} sm={6} md={12}>
                        <Tooltip title="Current Year" placement="bottom">
                          <Button
                            size="small"
                            style={{ width: "100%" }}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              changeFilterValues("startDate", firstOfYear);
                              changeFilterValues("endDate", lastOfYear);
                            }}
                          >
                            Year
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Panel>
        )}
      </Viz>
    </OuterContainer>
  );
};

export default DataViz;
