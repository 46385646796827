import { useEffect, useState } from "react";
import { scaleOrdinal } from "d3-scale";
import { schemePaired } from "d3-scale-chromatic";
import { useQuery } from "react-query";
import axios from "axios";
import { INIT_STYLE_VALUES } from "../../constants";

const useLayerStyles = ({ onLayerStyleChange, isMapLoaded }) => {
  const [styleValues, setStyleValues] = useState(INIT_STYLE_VALUES);

  const buildScale = (values) => {
    const scale = scaleOrdinal(schemePaired);
    return values.reduce((acc, val) => {
      acc.push([val]);
      acc.push(scale(val));
      return acc;
    }, []);
  };

  const { data } = useQuery(
    ["filterData2"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/filters`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setStyleValues((prevState) => ({
        ...prevState,
        sustainabilityStatus: {
          loaded: true,
          ...prevState.sustainabilityStatus,
          paint: {
            "circle-color": [
              "match",
              ["get", "sust_status_desc"],
              ...data.sustainabilityStatus.reduce((acc, val) => {
                acc.push([val.value]);
                acc.push(val.color);
                return acc;
              }, []),
              "#000",
            ],
            "circle-stroke-width": 2,
            "circle-stroke-color": "black",
          },
        },
        aquifers: {
          ...prevState.aquifers,
          paint: {
            "circle-color": [
              "match",
              ["get", "aquifer"],
              ...buildScale(data.aquifers.map((item) => item.display)),
              "#000",
            ],
            "circle-stroke-width": [
              "case",
              [">", ["length", ["get", "aquifer_array"]], 1],
              5,
              2,
            ],
            "circle-stroke-color": [
              "case",
              [">", ["length", ["get", "aquifer_array"]], 1],
              "white",
              "black",
            ],
          },
        },
        wellTypes: {
          ...prevState.wellTypes,
          paint: {
            "circle-color": [
              "match",
              ["get", "well_type"],
              ...buildScale(data.wellTypes.map((item) => item.display)),
              "#000",
            ],
            "circle-stroke-width": [
              "case",
              [">", ["length", ["get", "well_type_array"]], 1],
              5,
              2,
            ],
            "circle-stroke-color": [
              "case",
              [">", ["length", ["get", "well_type_array"]], 1],
              "white",
              "black",
            ],
          },
        },
        wellStatus: {
          ...prevState.wellStatus,
          paint: {
            "circle-color": [
              "match",
              ["get", "well_status"],
              ...buildScale(data.wellStatus.map((item) => item.display)),
              "#000",
            ],
            "circle-stroke-width": 2,
            "circle-stroke-color": "black",
          },
        },
      }));
    }
  }, [data]);

  const [activeStyle, setActiveStyle] = useState(
    styleValues.sustainabilityStatus
  );

  const styleOptions = Object.entries(styleValues).map(([key, value]) => ({
    display: value.name,
    value: key,
  }));

  const handleActiveStyle = (name) => {
    setActiveStyle(styleValues[name]);
    onLayerStyleChange(styleValues[name]);
  };

  useEffect(() => {
    if (isMapLoaded && styleValues.sustainabilityStatus.loaded) {
      handleActiveStyle("sustainabilityStatus");
    }
  }, [styleValues, isMapLoaded]); //eslint-disable-line

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
    styleValues,
  };
};

export default useLayerStyles;
