// Control implemented as ES6 class

class ResetZoomControl {
  onAdd(map) {
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    const icon = document.createElement("button");
    icon.type = "button";
    icon.className = "material-icons";
    icon.style.verticalAlign = "middle";
    icon.style.cursor = "pointer";
    icon.textContent = "explore";
    this._container.appendChild(icon);
    this._container.addEventListener("click", () => {
      // map.flyTo({
      //   center: STARTING_LOCATION,
      //   zoom: 8.6,
      //   padding: { bottom: 0 },
      // });
      map.fitBounds(
        [
          -101.2939453125, 40.001582957262826, -99.9810791015625,
          40.917404191689144,
        ]
        // { linear: true, duration: 0 }
      );
    });
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
  }
}

export default ResetZoomControl;
