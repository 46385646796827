import React, { useEffect, useState } from "react";
// import "./styles.css";
import styled from "styled-components/macro";
import { isNullOrUndef } from "chart.js/helpers";
import { formatBooleanTrueFalse } from "../../../utils";
import Button from "@material-ui/core/Button";
import { Pagination } from "@material-ui/lab";
import { titleize } from "inflected";

const PopupWrap = styled.div`
  height: ${({ height }) => height};
  overflow-y: scroll;
  width: ${({ width }) => width};
`;

const PopupTable = styled.table`
  border-radius: 5px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
`;

const PopupRow = styled.tr`
  border-radius: 5px;
  &:nth-child(even) {
    background-color: #eee;
  }
`;

const PopupCell = styled.td`
  padding: 3px 6px;
  margin: 0;
`;

const Popup = ({
  setDataVizVisible,
  setDataVizWellNumber,
  setDataVizGraphType,
  features,
  layers,
  height = "200px",
  width = "380px",
  size = "medium",
}) => {
  const dataVizGraphTypes = {
    "Time Series Data": "ui-graph-pumping-v-waterlevels",
  };
  function getUniqueFeatures(array, comparatorProperty1, comparatorProperty2) {
    const existingFeatureKeys = {};
    // Because features come from tiled vector data, feature geometries may be split
    // or duplicated across tile boundaries and, as a result, features may appear
    // multiple times in query results.
    //concat two ids to make a unique id
    return array.filter((el) => {
      if (
        existingFeatureKeys[
          el[comparatorProperty1] + el.layer[comparatorProperty2]
        ]
      ) {
        return false;
      } else {
        existingFeatureKeys[
          el[comparatorProperty1] + el.layer[comparatorProperty2]
        ] = true;
        return true;
      }
    });
  }

  const uniqueFeatures = getUniqueFeatures(features, "id", "id");

  const [page, setPage] = useState(1);
  const [feature, setFeature] = useState(uniqueFeatures?.[0]);
  const [excludeFields, setExcludeFields] = useState([]);
  const [titleField, setTitleField] = useState("");

  const handlePageChange = (e, p) => {
    setPage(p);
  };

  useEffect(() => {
    if (uniqueFeatures?.length) {
      setFeature(uniqueFeatures[page - 1]);
    }
  }, [uniqueFeatures, page]);

  useEffect(() => {
    const excludedFields = layers?.find(
      (layer) => layer?.id === feature?.layer?.id
    )?.lreProperties?.popup?.excludeFields;
    setExcludeFields(excludedFields || []);
  }, [feature, layers]);

  useEffect(() => {
    const title = layers?.find((layer) => layer?.id === feature?.layer?.id)
      ?.lreProperties?.popup?.titleField;
    setTitleField(
      (title &&
        feature?.properties[title] &&
        `${feature?.properties[title]} (${titleize(
          feature?.layer?.source
        )})`) ||
        titleize(feature?.layer?.source)
    );
  }, [feature, layers]);

  const addViewDataVizButtons = (key, value) => {
    if (value && key === "Time Series Data") {
      return (
        <>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {value + " "}
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                setDataVizVisible(true);
                setDataVizWellNumber(feature.properties.well_ndx);
                setDataVizGraphType(dataVizGraphTypes[key]);
              }}
            >
              {key}
            </Button>
          </span>
        </>
      );
    }
    return value;
  };
  let popupData;
  if (feature?.layer?.id === "castle-rock-wells-circle") {
    popupData = [
      ...(feature?.properties.has_pumping ||
      feature?.properties.has_water_levels
        ? [["Time Series Data", true]]
        : []),
      ["has_pumping", feature?.properties.has_pumping],
      ["has_water_levels", feature?.properties.has_water_levels],
      ["Water Levels", feature?.properties.por_waterlevels],
      ["Pumping", feature?.properties.por_pumping],
      ["ID", feature?.properties.well_id],
      ["Name", feature?.properties.well_name],
      ["Permit", feature?.properties.permit_number],
      ["Aquifers", feature?.properties.aquifer_array],
      ["Types", feature?.properties.well_type_array],
      ["Status", feature?.properties.well_status],
      ["Sustainability Status", feature?.properties.sust_status_desc],
      ["Designation", feature?.properties.designation],
      ["Elevation", feature?.properties.ground_elev_ft],
      ["Depth, Ft", feature?.properties.total_depth_ft],
      ["Aquifer Elevation", feature?.properties.aquifer_elevation],
      ["Screened Interval", feature?.properties.screened_interval],
      ["Completion Date", feature?.properties.completion_date],
      ["Has Well Report", feature?.properties.well_report],
      ["Has Drill Log", feature?.properties.drill_log],
      ["Notes", feature?.properties.notes],
      [
        "Most Recent Static Elevation",
        feature?.properties.most_recent_static_elev,
      ],
      ["Original Static Elevation", feature?.properties.orig_static_elev],
      ["Static WL Drop", feature?.properties.wl_drop_ft],
      ["MORE DETAILS", "TBD"],
    ];
  } else {
    popupData = excludeFields
      ? Object.entries(feature?.properties).reduce((acc, [key, value]) => {
          //MJB also removing entry if the value is an empty string, null, or undefined
          if (
            !excludeFields.includes(key) &&
            value !== "" &&
            !isNullOrUndef(value)
          ) {
            acc.push([key, value]);
          }
          return acc;
        }, [])
      : Object.entries(feature?.properties);
  }

  if (!popupData) return null;
  return (
    <>
      <h2>{titleField}</h2>
      <PopupWrap height={height} width={width}>
        <PopupTable>
          <tbody>
            {/*{currentUser?.isAdmin && feature.source === "castle-rock-wells" && (*/}
            {/*  <PopupRow>*/}
            {/*    <PopupCell>*/}
            {/*      <strong>Edit Well</strong>*/}
            {/*    </PopupCell>*/}
            {/*    <PopupCell>*/}
            {/*      <a*/}
            {/*        href={`/models/dm-wells/${feature?.properties?.id}`}*/}
            {/*        target="_blank"*/}
            {/*        rel="noreferrer noopener"*/}
            {/*      >*/}
            {/*        Link*/}
            {/*      </a>*/}
            {/*    </PopupCell>*/}
            {/*  </PopupRow>*/}
            {/*)}*/}

            {popupData?.map(([key, value]) => {
              return (
                <PopupRow key={key}>
                  <PopupCell>
                    <strong>{titleize(key)}</strong>
                  </PopupCell>
                  <PopupCell>
                    {formatBooleanTrueFalse(addViewDataVizButtons(key, value))}
                  </PopupCell>
                </PopupRow>
              );
            })}
          </tbody>
        </PopupTable>
      </PopupWrap>
      <Pagination
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
        count={uniqueFeatures.length}
        size={size}
        page={page}
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={handlePageChange}
      />
    </>
  );
};

export default Popup;
