export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-104.84711, 39.37388];

export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  center: DEFAULT_MAP_CENTER,
  zoom: 11,
  preserveDrawingBuffer: true,
};

export const WELLS_LAYER_ID = "castle-rock-wells-circle";
export const WELLS_LABELS_LAYER_ID = "castle-rock-wells-symbol";

const layerId = "castle-rock-wells-circle";
export const INIT_STYLE_VALUES = {
  sustainabilityStatus: {
    id: "sustainabilityStatus",
    layerId,
    layerFieldName: "sust_status_desc",
    name: "Sustainability Status",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "sust_status_desc"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  aquifers: {
    id: "aquifers",
    layerId,
    layerFieldName: "aquifer",
    name: "Aquifer",
    paint: {
      "circle-color": [
        "match",
        ["get", "aquifer"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  wellTypes: {
    id: "wellTypes",
    layerId,
    layerFieldName: "well_type",
    name: "Well Type",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "well_type"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  wellStatus: {
    id: "wellStatus",
    layerId,
    layerFieldName: "well_status",
    name: "Well Status",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "well_status"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  totalDepth: {
    id: "totalDepth",
    layerId,
    layerFieldName: "total_depth_ft",
    name: "Total Depth ft",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "case",
        // ...buildScaleNumbers(certAcresValues, ["get", "cert_acres"]),
        ["<", ["get", "total_depth_ft"], 100],
        "#FE0000",
        ["<", ["get", "total_depth_ft"], 1000],
        "#FFA500",
        ["<", ["get", "total_depth_ft"], 1500],
        "#FFFB00",
        ["<", ["get", "total_depth_ft"], 2000],
        "#009A00",
        ["<", ["get", "total_depth_ft"], 2500],
        "#0030FF",
        [">=", ["get", "total_depth_ft"], 2500],
        "#800080",
        "#000000",
      ],
    },
  },
  staticWlDrop: {
    id: "staticWlDrop",
    layerId,
    layerFieldName: "wl_drop_ft",
    name: "Static WL Drop",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "case",
        // ...buildScaleNumbers(certAcresValues, ["get", "cert_acres"]),
        ["<", ["get", "wl_drop_ft"], 100],
        "#800080",
        ["<", ["get", "wl_drop_ft"], 200],
        "#0030FF",
        ["<", ["get", "wl_drop_ft"], 300],
        "#009A00",
        ["<", ["get", "wl_drop_ft"], 400],
        "#FFFB00",
        ["<", ["get", "wl_drop_ft"], 500],
        "#FFA500",
        [">=", ["get", "wl_drop_ft"], 500],
        "#FE0000",
        "#000000",
      ],
    },
  },
  mostRecentStaticElevation: {
    id: "mostRecentStaticElevation",
    layerId,
    layerFieldName: "most_recent_static_elev",
    name: "Most Recent Static Elevation",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "case",
        // ...buildScaleNumbers(certAcresValues, ["get", "cert_acres"]),
        ["<", ["get", "most_recent_static_elev"], 4800],
        "#FE0000",
        ["<", ["get", "most_recent_static_elev"], 5100],
        "#FFA500",
        ["<", ["get", "most_recent_static_elev"], 5400],
        "#FFFB00",
        ["<", ["get", "most_recent_static_elev"], 5700],
        "#009A00",
        ["<", ["get", "most_recent_static_elev"], 6000],
        "#0030FF",
        [">=", ["get", "most_recent_static_elev"], 6000],
        "#800080",
        "#000000",
      ],
    },
  },
};

export const INIT_FILTER_VALUES = {
  sustainabilityStatus: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "sust_status_desc",
    options: [],
    type: "multi-select",
    value: [],
  },
  aquifers: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "aquifer_array",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  wellTypes: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "well_type_array",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  wellStatus: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "well_status",
    options: [],
    type: "multi-select",
    value: [],
  },
  hasWellReport: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "well_report",
    type: "boolean",
    value: false,
  },
  hasDrillLog: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "drill_log",
    type: "boolean",
    value: false,
  },
  hasWaterLevels: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_water_levels",
    type: "boolean",
    value: false,
  },
  hasPumping: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_pumping",
    type: "boolean",
    value: false,
  },
};
